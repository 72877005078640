import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { ProductCreateNestedOneWithoutVariantsInputObjectSchema } from './ProductCreateNestedOneWithoutVariantsInput.schema'
import { VariantWarehouseCreateNestedManyWithoutVariantInputObjectSchema } from './VariantWarehouseCreateNestedManyWithoutVariantInput.schema'
import { FlashsaleItemCreateNestedManyWithoutVariantInputObjectSchema } from './FlashsaleItemCreateNestedManyWithoutVariantInput.schema'
import { VariantPictureCreateNestedManyWithoutVariantInputObjectSchema } from './VariantPictureCreateNestedManyWithoutVariantInput.schema'
import { ProductReviewCreateNestedManyWithoutVariantInputObjectSchema } from './ProductReviewCreateNestedManyWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.VariantCreateWithoutVariantOnCustomFieldsInput> =
  z
    .object({
      active: z.boolean().optional(),
      sku: z.string(),
      batchAndExpireDate: z.string().optional().nullable(),
      size: z.number().optional().nullable(),
      cost: z.number().optional(),
      tagPrice: z.number().optional().nullable(),
      sellingPrice: z.number(),
      inventory: z.number().optional(),
      canExpress: z.boolean().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      v2DrugItemId: z.number().optional().nullable(),
      inventoryUpdatedAt: z.date().optional().nullable(),
      unit: z
        .lazy(() => UnitSchema)
        .optional()
        .nullable(),
      sellingUnit: z
        .lazy(() => UnitSchema)
        .optional()
        .nullable(),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      rank: z.number().optional(),
      product: z.lazy(
        () => ProductCreateNestedOneWithoutVariantsInputObjectSchema,
      ),
      variantWarehouses: z
        .lazy(
          () => VariantWarehouseCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      flashsaleItem: z
        .lazy(
          () => FlashsaleItemCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () => VariantPictureCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      productReviews: z
        .lazy(
          () => ProductReviewCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema = Schema

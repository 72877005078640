import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableEnumUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUnitFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { VariantWarehouseUncheckedUpdateManyWithoutVariantNestedInputObjectSchema } from './VariantWarehouseUncheckedUpdateManyWithoutVariantNestedInput.schema'
import { FlashsaleItemUncheckedUpdateManyWithoutVariantNestedInputObjectSchema } from './FlashsaleItemUncheckedUpdateManyWithoutVariantNestedInput.schema'
import { VariantPictureUncheckedUpdateManyWithoutVariantNestedInputObjectSchema } from './VariantPictureUncheckedUpdateManyWithoutVariantNestedInput.schema'
import { VariantOnCustomFieldUncheckedUpdateManyWithoutVariantNestedInputObjectSchema } from './VariantOnCustomFieldUncheckedUpdateManyWithoutVariantNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.VariantUncheckedUpdateWithoutProductReviewsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      sku: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      productId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      batchAndExpireDate: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      size: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      cost: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      tagPrice: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      sellingPrice: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      inventory: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      canExpress: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2DrugItemId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      inventoryUpdatedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      unit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      sellingUnit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      descriptionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      rank: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      variantWarehouses: z
        .lazy(
          () =>
            VariantWarehouseUncheckedUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      flashsaleItem: z
        .lazy(
          () =>
            FlashsaleItemUncheckedUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () =>
            VariantPictureUncheckedUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      variantOnCustomFields: z
        .lazy(
          () =>
            VariantOnCustomFieldUncheckedUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const VariantUncheckedUpdateWithoutProductReviewsInputObjectSchema =
  Schema

import { z } from 'zod'
import { CustomFieldModel } from '../products'

// Define the field types as a const array for type safety
const CUSTOM_FIELD_TYPES = [
  'auxiliaryLabel',
  'drugClassification',
  'productForms',
  'skinType',
  'concerns',
  'attributes',
  'productUsage',
  'productUsageUnit',
  'productUsageFrequency',
] as const

export const ProductCustomFieldsModel = z.object({
  auxiliaryLabel: z.array(CustomFieldModel).nullable(),
  drugClassification: z.array(CustomFieldModel).nullable(),
  productForms: z.array(CustomFieldModel).nullable(),
  skinType: z.array(CustomFieldModel).nullable(),
  concerns: z.array(CustomFieldModel).nullable(),
  attributes: z.array(CustomFieldModel).nullable(),
  productUsage: z.array(CustomFieldModel).nullable(),
  productUsageUnit: z.array(CustomFieldModel).nullable(),
  productUsageFrequency: z.array(CustomFieldModel).nullable(),
})

/** CustomFields with coerced date fields */
export const CustomFieldCoerceDateModel = CustomFieldModel.merge(
  z.object({
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
  }),
)

export const CustomFieldStringDateModel = CustomFieldModel.merge(
  z.object({
    createdAt: z.coerce.string(),
    updatedAt: z.coerce.string(),
  }),
)

export const CustomFieldGatewayInputModel =
  CustomFieldStringDateModel.partial().required({
    id: true,
  })

export type ProductCustomFields = z.infer<typeof ProductCustomFieldsModel>
export type CustomField = z.infer<typeof CustomFieldModel>
export type CustomFieldType = typeof CUSTOM_FIELD_TYPES[number]

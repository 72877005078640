import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteProduct, RelatedProductModel, CompleteVariantWarehouse, RelatedVariantWarehouseModel, CompleteFlashsaleItem, RelatedFlashsaleItemModel, CompleteVariantPicture, RelatedVariantPictureModel, CompleteProductReview, RelatedProductReviewModel, CompleteVariantOnCustomField, RelatedVariantOnCustomFieldModel } from "./index"

export const VariantModel = z.object({
  id: z.number().int(),
  active: z.boolean(),
  sku: z.string(),
  productId: z.number().int(),
  batchAndExpireDate: z.string().nullish(),
  size: z.number().nullish(),
  cost: z.number(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  inventory: z.number().int(),
  canExpress: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  v2DrugItemId: z.number().int().nullish(),
  inventoryUpdatedAt: z.date().nullish(),
  unit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  sellingUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  rank: z.number().int(),
})

export const VariantModelResponse = z.object({
  id: z.number().int(),
  active: z.boolean(),
  sku: z.string(),
  productId: z.number().int(),
  batchAndExpireDate: z.string().nullish(),
  size: z.number().nullish(),
  cost: z.number(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  inventory: z.number().int(),
  canExpress: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  v2DrugItemId: z.number().int().nullish(),
  inventoryUpdatedAt: z.date().nullish(),
  unit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  sellingUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  descriptionTr: jsonSchema,
  rank: z.number().int(),
})

export interface CompleteVariant extends z.infer<typeof VariantModel> {
  product: CompleteProduct
  variantWarehouses: CompleteVariantWarehouse[]
  flashsaleItem: CompleteFlashsaleItem[]
  pictures: CompleteVariantPicture[]
  productReviews: CompleteProductReview[]
  variantOnCustomFields: CompleteVariantOnCustomField[]
}

/**
 * RelatedVariantModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVariantModel: z.ZodSchema<CompleteVariant> = z.lazy(() => VariantModel.extend({
  product: RelatedProductModel,
  variantWarehouses: RelatedVariantWarehouseModel.array(),
  flashsaleItem: RelatedFlashsaleItemModel.array(),
  pictures: RelatedVariantPictureModel.array(),
  productReviews: RelatedProductReviewModel.array(),
  variantOnCustomFields: RelatedVariantOnCustomFieldModel.array(),
}))

import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { VariantWarehouseUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedCreateNestedManyWithoutVariantInput.schema'
import { FlashsaleItemUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedCreateNestedManyWithoutVariantInput.schema'
import { VariantPictureUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './VariantPictureUncheckedCreateNestedManyWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateNestedManyWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.VariantUncheckedCreateWithoutVariantOnCustomFieldsInput> =
  z
    .object({
      id: z.number().optional(),
      active: z.boolean().optional(),
      sku: z.string(),
      productId: z.number(),
      batchAndExpireDate: z.string().optional().nullable(),
      size: z.number().optional().nullable(),
      cost: z.number().optional(),
      tagPrice: z.number().optional().nullable(),
      sellingPrice: z.number(),
      inventory: z.number().optional(),
      canExpress: z.boolean().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      v2DrugItemId: z.number().optional().nullable(),
      inventoryUpdatedAt: z.date().optional().nullable(),
      unit: z
        .lazy(() => UnitSchema)
        .optional()
        .nullable(),
      sellingUnit: z
        .lazy(() => UnitSchema)
        .optional()
        .nullable(),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      rank: z.number().optional(),
      variantWarehouses: z
        .lazy(
          () =>
            VariantWarehouseUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      flashsaleItem: z
        .lazy(
          () =>
            FlashsaleItemUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () =>
            VariantPictureUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
      productReviews: z
        .lazy(
          () =>
            ProductReviewUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema =
  Schema

import { TrFieldModel } from '@ssch-backend/share-model'
import { z } from 'zod'

import {
  CustomFieldModel,
  CustomFieldModelResponse,
  FlashsaleItemModel,
  ProductCustomFieldModel,
  VariantModel,
  VariantModelResponse,
  VariantOnCustomFieldModel,
  WarehouseModel,
} from '../../generated/zod'
import { ProductResponseModel } from './product.model'

export const GetProductVariantInputModel = z.object({
  productVariantId: z.number().int(),
})
export const ProductSKUPictureSchema = z.object({
  src: z.string(),
  gsPath: z.string(),
})
export const SearchProductVariantInputModel = z.object({
  page: z.number().default(1),
  limit: z.number().default(10),
  productVariantId: z.number().int().optional(),
  productVariantIds: z.preprocess(
    (val) =>
      typeof val === 'string' ? String(val).split(',').map(Number) : val,
    z.number().array().optional(),
  ),
  productId: z.number().int().optional(),
  orderBy: z.string().optional(),
  orderDirection: z.enum(['asc', 'desc']).optional(),
  product: z.string().optional(),
  sku: z.string().optional(),
  warehouseId: z.preprocess(
    (val) =>
      typeof val === 'string' ? String(val).split(',').map(Number) : val,
    z.number().array().optional(),
  ),
  relatedFields: z.boolean().optional(),
})
export const ProductVariantInputModel = z.object({
  id: z.number().int(),
  sku: z.string(),
  active: z.boolean().default(true),
  productId: z.number().int(),
  descriptionTr: TrFieldModel,
  batchAndExpireDate: z.string().optional(),
  size: z.number().positive().optional(),
  cost: z.number().positive().default(0),
  tagPrice: z.number().positive().optional(),
  sellingPrice: z.number().positive(),
  inventory: z.number().min(0).default(0),
  warehouseIds: z.number().array(),
  canExpress: z.boolean(),
  customFieldIds: z.number().array(),
  pictures: z.array(ProductSKUPictureSchema),
})

export const CreateProductVariantInputModel = ProductVariantInputModel.omit({
  id: true,
  active: true,
})
export const UpdateProductVariantInputModel = ProductVariantInputModel.omit({
  id: true,
})
  .partial()
  .extend({
    productVariantId: z.number().int(),
  })

export type GetProductVariantInput = z.infer<typeof GetProductVariantInputModel>
export type SearchProductVariantInput = z.infer<
  typeof SearchProductVariantInputModel
>
export type CreateProductVariantInput = z.infer<
  typeof CreateProductVariantInputModel
>
export type UpdateProductVariantInput = z.infer<
  typeof UpdateProductVariantInputModel
>

export const ProductVariantResponseModel = VariantModelResponse.omit({
  descriptionTr: true,
}).extend({
  descriptionTr: TrFieldModel,
})

export const SearchProductVariantResponseModel = z.object({
  productVariants: ProductVariantResponseModel.array(),
  count: z.number(),
})
export type SearchProductVariantResponse = z.infer<
  typeof SearchProductVariantResponseModel
>

export const ProductVariantResponseWithRelatedFieldModel =
  ProductVariantResponseModel.extend({
    product: ProductResponseModel,
    customFields: z.object({
      variantUnit: z.array(CustomFieldModel).nullable(),
      variantSellingUnit: z.array(CustomFieldModel).nullable(),
    }),
    warehouses: WarehouseModel.array(),
    pictures: z.array(ProductSKUPictureSchema),
  })
export type ProductVariantResponseWithRelatedField = z.infer<
  typeof ProductVariantResponseWithRelatedFieldModel
>

export const ProductVariantResponseWithRelatedFieldAndProductCustomFieldModel =
  ProductVariantResponseWithRelatedFieldModel.omit({ product: true }).extend({
    product: ProductResponseModel.omit({
      auxiliaryLabel: true,
      productForms: true,
      skinType: true,
      concerns: true,
      attributes: true,
      usage: true,
      useUnit: true,
      useFrequency: true,
    }).extend({
      customFields: z.object({
        auxiliaryLabel: z.array(CustomFieldModel).nullable(),
        drugClassification: z.array(CustomFieldModel).nullable(),
        productForms: z.array(CustomFieldModel).nullable(),
        skinType: z.array(CustomFieldModel).nullable(),
        concerns: z.array(CustomFieldModel).nullable(),
        attributes: z.array(CustomFieldModel).nullable(),
        productUsage: z.array(CustomFieldModel).nullable(),
        productUsageUnit: z.array(CustomFieldModel).nullable(),
        productUsageFrequency: z.array(CustomFieldModel).nullable(),
      }),
    }),
  })
export type ProductVariantResponseWithRelatedFieldAndProductCustomField =
  z.infer<
    typeof ProductVariantResponseWithRelatedFieldAndProductCustomFieldModel
  >

export const SearchVariantWithProductAndCustomFieldResponseModel = z.object({
  productVariants: ProductVariantResponseWithRelatedFieldModel.omit({
    pictures: true,
  }).array(),
  count: z.number(),
})
export type SearchVariantWithProductAndCustomFieldResponse = z.infer<
  typeof SearchVariantWithProductAndCustomFieldResponseModel
>

export const SearchVariantWithDetailResponseModel = z.object({
  productVariants: ProductVariantResponseWithRelatedFieldModel.array(),
  count: z.number(),
})
export type SearchVariantWithDetailResponse = z.infer<
  typeof SearchVariantWithDetailResponseModel
>

const ProductOnCustomFieldModelIncludeField = ProductCustomFieldModel.extend({
  field: CustomFieldModelResponse,
})
export type ProductOnCustomFieldModelIncludeField = z.infer<
  typeof ProductOnCustomFieldModelIncludeField
>

const VariantOnCustomFieldModelIncludeField = VariantOnCustomFieldModel.extend({
  field: CustomFieldModelResponse,
})
export type VariantOnCustomFields = z.infer<
  typeof VariantOnCustomFieldModelIncludeField
>

export const VariantWithVariantOnCustomFieldsModel = VariantModel.extend({
  variantOnCustomFields: z.array(VariantOnCustomFieldModelIncludeField),
})
export type VariantWithVariantOnCustomFields = z.infer<
  typeof VariantWithVariantOnCustomFieldsModel
>

export const ProductVariantResponseWithFlashsaleItemModel =
  ProductVariantResponseWithRelatedFieldModel.omit({
    warehouses: true,
  }).extend({
    flashsaleItem: z.array(FlashsaleItemModel),
  })
export type ProductVariantResponseWithFlashsaleItem = z.infer<
  typeof ProductVariantResponseWithFlashsaleItemModel
>

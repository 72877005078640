import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { EnumProductTypeFieldUpdateOperationsInputObjectSchema } from './EnumProductTypeFieldUpdateOperationsInput.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { EnumSalesTypeFieldUpdateOperationsInputObjectSchema } from './EnumSalesTypeFieldUpdateOperationsInput.schema'
import { DrugGroupSchema } from '../enums/DrugGroup.schema'
import { NullableEnumDrugGroupFieldUpdateOperationsInputObjectSchema } from './NullableEnumDrugGroupFieldUpdateOperationsInput.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { NullableEnumDrugTypeFieldUpdateOperationsInputObjectSchema } from './NullableEnumDrugTypeFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { NullableEnumAuxiliaryLabelFieldUpdateOperationsInputObjectSchema } from './NullableEnumAuxiliaryLabelFieldUpdateOperationsInput.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { NullableEnumUsageFieldUpdateOperationsInputObjectSchema } from './NullableEnumUsageFieldUpdateOperationsInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableEnumUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUnitFieldUpdateOperationsInput.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { NullableEnumUseFrequencyFieldUpdateOperationsInputObjectSchema } from './NullableEnumUseFrequencyFieldUpdateOperationsInput.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { NullableEnumUseTimeFieldUpdateOperationsInputObjectSchema } from './NullableEnumUseTimeFieldUpdateOperationsInput.schema'
import { ProductCategoryUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductCategoryUncheckedUpdateManyWithoutProductNestedInput.schema'
import { VariantUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './VariantUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductPictureUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductPictureUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductVideoUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductVideoUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductReviewUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductReviewUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProductUncheckedUpdateWithoutProductReviewSummaryInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      searchable: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      nameTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      searchField: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      regNo: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      brandId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      type: z
        .union([
          z.lazy(() => ProductTypeSchema),
          z.lazy(() => EnumProductTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      priority: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      salesType: z
        .union([
          z.lazy(() => SalesTypeSchema),
          z.lazy(() => EnumSalesTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      drugGroup: z
        .union([
          z.lazy(() => DrugGroupSchema),
          z.lazy(
            () => NullableEnumDrugGroupFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      propertyTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      drugType: z
        .union([
          z.lazy(() => DrugTypeSchema),
          z.lazy(
            () => NullableEnumDrugTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      descriptionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      sideEffectTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      cautionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      genericTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      genericSearch: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      useAmount: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      instructionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      pharmacyNoteTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      pharmacyNote: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      deletedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      v2DrugItemId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      auxiliaryLabel: z
        .union([
          z.lazy(() => AuxiliaryLabelSchema),
          z.lazy(
            () =>
              NullableEnumAuxiliaryLabelFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      productForms: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      skinType: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      concerns: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      attributes: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      usage: z
        .union([
          z.lazy(() => UsageSchema),
          z.lazy(() => NullableEnumUsageFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useUnit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useFrequency: z
        .union([
          z.lazy(() => UseFrequencySchema),
          z.lazy(
            () =>
              NullableEnumUseFrequencyFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      useTime: z
        .union([
          z.lazy(() => UseTimeSchema),
          z.lazy(
            () => NullableEnumUseTimeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      ingredient: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      categories: z
        .lazy(
          () =>
            ProductCategoryUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      variants: z
        .lazy(
          () => VariantUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () =>
            ProductPictureUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      video: z
        .lazy(
          () =>
            ProductVideoUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      productReviews: z
        .lazy(
          () =>
            ProductReviewUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      productOnCustomField: z
        .lazy(
          () =>
            ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const ProductUncheckedUpdateWithoutProductReviewSummaryInputObjectSchema =
  Schema

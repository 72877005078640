import { z } from 'zod'
import { SuggestedProductItemModel } from '../teleconsults'
import {
  CustomFieldCoerceDateModel,
  CustomFieldGatewayInputModel,
  CustomFieldStringDateModel,
} from './productCustomField.model'

export const SuggestedProductCustomFieldGatewayInputSchema = z.object({
  productUsage: CustomFieldGatewayInputModel,
  productUsageUnit: CustomFieldGatewayInputModel,
  productUsageFrequency: CustomFieldGatewayInputModel,
})
export type CustomFieldGatewayInput = z.infer<
  typeof CustomFieldGatewayInputModel
>

export const SuggestedProductCustomFieldSchema = z.object({
  productUsage: CustomFieldCoerceDateModel.nullable(),
  productUsageUnit: CustomFieldCoerceDateModel.nullable(),
  productUsageFrequency: CustomFieldCoerceDateModel.nullable(),
})

export const SuggestedProductItemWithCustomFieldSchema =
  SuggestedProductItemModel.omit({
    usage: true,
    useUnit: true,
    useTime: true,
    useFrequency: true,
  }).extend({
    customFields: SuggestedProductCustomFieldSchema,
  })

export const SuggestedProductItemWithCustomFieldInputSchema =
  SuggestedProductItemModel.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    caseSummaryId: true,
    warehouse: true,
    usage: true,
    useUnit: true,
    useTime: true,
    useFrequency: true,
  }).extend({
    customFields: z.object({
      productUsage: CustomFieldStringDateModel,
      productUsageUnit: CustomFieldStringDateModel,
      productUsageFrequency: CustomFieldStringDateModel,
    }),
    warehouse: z.array(z.string()).default([]),
  })
export type SuggestedProductItemWithCustomFieldInput = z.infer<
  typeof SuggestedProductItemWithCustomFieldInputSchema
>

/** For gateway (require only Id) */
export const SuggestedProductItemWithCustomFieldGatewayInputSchema =
  SuggestedProductItemWithCustomFieldInputSchema.omit({
    customFields: true,
  }).extend({
    customFields: SuggestedProductCustomFieldGatewayInputSchema,
  })
export type SuggestedProductItemWithCustomFieldGatewayInput = z.infer<
  typeof SuggestedProductItemWithCustomFieldGatewayInputSchema
>

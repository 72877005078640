import { z } from 'zod'

export const VariantScalarFieldEnumSchema = z.enum([
  'id',
  'active',
  'sku',
  'productId',
  'batchAndExpireDate',
  'size',
  'cost',
  'tagPrice',
  'sellingPrice',
  'inventory',
  'canExpress',
  'createdAt',
  'updatedAt',
  'v2DrugItemId',
  'inventoryUpdatedAt',
  'unit',
  'sellingUnit',
  'descriptionTr',
  'rank',
])

import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { BoolWithAggregatesFilterObjectSchema } from './BoolWithAggregatesFilter.schema'
import { JsonWithAggregatesFilterObjectSchema } from './JsonWithAggregatesFilter.schema'
import { StringWithAggregatesFilterObjectSchema } from './StringWithAggregatesFilter.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'
import { EnumProductTypeWithAggregatesFilterObjectSchema } from './EnumProductTypeWithAggregatesFilter.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { EnumSalesTypeWithAggregatesFilterObjectSchema } from './EnumSalesTypeWithAggregatesFilter.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { EnumDrugGroupNullableWithAggregatesFilterObjectSchema } from './EnumDrugGroupNullableWithAggregatesFilter.schema'
import { DrugGroupSchema } from '../enums/DrugGroup.schema'
import { EnumDrugTypeNullableWithAggregatesFilterObjectSchema } from './EnumDrugTypeNullableWithAggregatesFilter.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { DateTimeNullableWithAggregatesFilterObjectSchema } from './DateTimeNullableWithAggregatesFilter.schema'
import { IntNullableWithAggregatesFilterObjectSchema } from './IntNullableWithAggregatesFilter.schema'
import { EnumAuxiliaryLabelNullableWithAggregatesFilterObjectSchema } from './EnumAuxiliaryLabelNullableWithAggregatesFilter.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { JsonNullableWithAggregatesFilterObjectSchema } from './JsonNullableWithAggregatesFilter.schema'
import { EnumUsageNullableWithAggregatesFilterObjectSchema } from './EnumUsageNullableWithAggregatesFilter.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { EnumUnitNullableWithAggregatesFilterObjectSchema } from './EnumUnitNullableWithAggregatesFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { EnumUseFrequencyNullableWithAggregatesFilterObjectSchema } from './EnumUseFrequencyNullableWithAggregatesFilter.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseTimeNullableWithAggregatesFilterObjectSchema } from './EnumUseTimeNullableWithAggregatesFilter.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductScalarWhereWithAggregatesInputObjectSchema),
        z.lazy(() => ProductScalarWhereWithAggregatesInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductScalarWhereWithAggregatesInputObjectSchema),
        z.lazy(() => ProductScalarWhereWithAggregatesInputObjectSchema).array(),
      ])
      .optional(),
    id: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolWithAggregatesFilterObjectSchema), z.boolean()])
      .optional(),
    searchable: z
      .union([z.lazy(() => BoolWithAggregatesFilterObjectSchema), z.boolean()])
      .optional(),
    nameTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    searchField: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    regNo: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    brandId: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumProductTypeWithAggregatesFilterObjectSchema),
        z.lazy(() => ProductTypeSchema),
      ])
      .optional(),
    priority: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    salesType: z
      .union([
        z.lazy(() => EnumSalesTypeWithAggregatesFilterObjectSchema),
        z.lazy(() => SalesTypeSchema),
      ])
      .optional(),
    drugGroup: z
      .union([
        z.lazy(() => EnumDrugGroupNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => DrugGroupSchema),
      ])
      .optional()
      .nullable(),
    propertyTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    drugType: z
      .union([
        z.lazy(() => EnumDrugTypeNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => DrugTypeSchema),
      ])
      .optional()
      .nullable(),
    descriptionTr: z
      .lazy(() => JsonWithAggregatesFilterObjectSchema)
      .optional(),
    sideEffectTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    cautionTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    genericTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    genericSearch: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    useAmount: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    instructionTr: z
      .lazy(() => JsonWithAggregatesFilterObjectSchema)
      .optional(),
    pharmacyNoteTr: z
      .lazy(() => JsonWithAggregatesFilterObjectSchema)
      .optional(),
    pharmacyNote: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    createdAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    deletedAt: z
      .union([
        z.lazy(() => DateTimeNullableWithAggregatesFilterObjectSchema),
        z.date(),
      ])
      .optional()
      .nullable(),
    v2DrugItemId: z
      .union([
        z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
        z.number(),
      ])
      .optional()
      .nullable(),
    auxiliaryLabel: z
      .union([
        z.lazy(
          () => EnumAuxiliaryLabelNullableWithAggregatesFilterObjectSchema,
        ),
        z.lazy(() => AuxiliaryLabelSchema),
      ])
      .optional()
      .nullable(),
    productForms: z
      .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
      .optional(),
    skinType: z
      .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
      .optional(),
    concerns: z
      .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
      .optional(),
    attributes: z
      .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
      .optional(),
    usage: z
      .union([
        z.lazy(() => EnumUsageNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => UsageSchema),
      ])
      .optional()
      .nullable(),
    useUnit: z
      .union([
        z.lazy(() => EnumUnitNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional()
      .nullable(),
    useFrequency: z
      .union([
        z.lazy(() => EnumUseFrequencyNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => UseFrequencySchema),
      ])
      .optional()
      .nullable(),
    useTime: z
      .union([
        z.lazy(() => EnumUseTimeNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => UseTimeSchema),
      ])
      .optional()
      .nullable(),
    ingredient: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ProductScalarWhereWithAggregatesInputObjectSchema = Schema

import 'react-image-crop/dist/ReactCrop.css'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ComponentType, FC, useEffect } from 'react'
import { Admin, CustomRoutes, Resource, useAuthProvider } from 'react-admin'
import { BrowserRouter, Route, useNavigate } from 'react-router-dom'
import authProvider from '../authProvider'
import { LoadingProvider } from '../components/GlobalLoading'
import dataProvider from '../dataProvider'
import { withPermissionRoutePage } from '../hoc/withPermissionRoutePage'
import i18nProvider from '../i18nProvider'
import { getResourcePermission } from '../permission'
import { AdminCreate } from './admin/create'
import { AdminEdit } from './admin/edit'
import { AdminList } from './admin/list'
import { AppointmentCreate } from './appointment/create'
import { AppointmentEdit } from './appointment/edit'
import { AppointmentList } from './appointment/list'
import { ArticleCreate } from './article/create'
import { ArticleEdit } from './article/edit'
import { ArticleList } from './article/list'
import { CarouselItemProcedureCreate } from './carouselItemProcedure/create'
import { CarouselItemProcedureEdit } from './carouselItemProcedure/edit'
import { CarouselProcedureCreate } from './carouselProcedure/create'
import { CarouselProcedureEdit } from './carouselProcedure/edit'
import { CarouselProcedureList } from './carouselProcedure/list'
import { CouponList } from './coupon/list'
import { CouponGroupCreate } from './couponGroup/create'
import { CouponGroupList } from './couponGroup/list'
import { CouponGroupShow } from './couponGroup/show'
import { DoctorCreate } from './doctor/create'
import { DoctorEdit } from './doctor/edit'
import { DoctorList } from './doctor/list'
import { DoctorBannerCreate } from './doctorBanner/create'
import { DoctorBannerEdit } from './doctorBanner/edit'
import { DoctorBannerList } from './doctorBanner/list'
import { DoctorCategoryCreate } from './doctorCategory/create'
import { DoctorCategoryEdit } from './doctorCategory/edit'
import { DoctorCategoryList } from './doctorCategory/list'
import { DoctorConsentEdit } from './doctorConsent/edit'
import { DoctorConsentList } from './doctorConsent/list'
import { DoctorKycDataEdit } from './doctorKycData/edit'
import { DoctorKycDataList } from './doctorKycData/list'
import { DoctorScheduler } from './doctorScheduler/index'
import { FlashsaleItemProcedureCreate } from './flashsaleItemProcedure/create'
import { FlashsaleItemProcedureEdit } from './flashsaleItemProcedure/edit'
import { FlashsaleItemProductCreate } from './flashsaleItemProduct/create'
import { FlashsaleItemProductEdit } from './flashsaleItemProduct/edit'
import { FlashsaleProcedureCreate } from './flashsaleProcedure/create'
import { FlashsaleProcedureEdit } from './flashsaleProcedure/edit'
import { FlashsaleProcedureList } from './flashsaleProcedure/list'
import { FlashsaleProductCreate } from './flashsaleProduct/create'
import { FlashsaleProductEdit } from './flashsaleProduct/edit'
import { FlashsaleProductList } from './flashsaleProduct/list'
import { Layout } from './layout'
import { NotificationCreate } from './notification/create'
import { NotificationList } from './notification/list'
import { PartnerCreate } from './partner/create'
import { PartnerEdit } from './partner/edit'
import { PartnerList } from './partner/list'
import { PartnerBranchCreate } from './partnerBranch/create'
import { PartnerBranchEdit } from './partnerBranch/edit'
import { PartnerUserCreate } from './partnerUser/create'
import { PartnerUserEdit } from './partnerUser/edit'
import { PartnerUserList } from './partnerUser/list'
import { PatientEdit } from './patient/edit'
import { PatientList } from './patient/list'
import { PatientConsentEdit } from './patientConsent/edit'
import { PatientConsentList } from './patientConsent/list'
import { PatientHomeBannerCreate } from './patientHomeBanner/create'
import { PatientHomeBannerEdit } from './patientHomeBanner/edit'
import { PatientHomeBannerList } from './patientHomeBanner/list'
import { PatientKycDataEdit } from './patientKycData/edit'
import { PatientKycDdataList } from './patientKycData/list'
import { ProcedureCreate } from './procedure/create'
import { ProcedureEdit } from './procedure/edit'
import { ProcedureList } from './procedure/list'
import { ProcedureBannerCreate } from './procedureBanner/create'
import { ProcedureBannerEdit } from './procedureBanner/edit'
import { ProcedureBannerList } from './procedureBanner/list'
import { ProcedureCategoryCreate } from './procedureCategory/create'
import { ProcedureCategoryEdit } from './procedureCategory/edit'
import { ProcedureCategoryList } from './procedureCategory/list'
import { ProcedureSearchAppPlaygroundList } from './procedureSearchAppPlayground/list'
import { ProductBannerCreate } from './productBanner/create'
import { ProductBannerEdit } from './productBanner/edit'
import { ProductBannerList } from './productBanner/list'
import { ProductBrandCreate } from './productBrand/create'
import { ProductBrandEdit } from './productBrand/edit'
import { ProductBrandList } from './productBrand/list'
import { ProductCategoryCreate } from './productCategory/create'
import { ProductCategoryEdit } from './productCategory/edit'
import { ProductCategoryList } from './productCategory/list'
import { ProductSearchAppPlaygroundList } from './productSearchAppPlayground/list'
import { QueueMonitoring } from './queueMonitoring/list'
import { ShippingProviderEdit } from './shippingProvider/edit'
import { ShippingProviderList } from './shippingProvider/list'
import { theme } from './theme'
import { WaitingBannerCreate } from './waitingBanner/create'
import { WaitingBannerEdit } from './waitingBanner/edit'
import { WaitingBannerList } from './waitingBanner/list'

const HomePage: FC = () => {
  const authProvider = useAuthProvider()
  const navigate = useNavigate()

  useEffect(() => {
    authProvider.getIdentity?.().catch((_e) => {
      navigate('/login')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const App = () => {
  const permissionComponent = (
    component: ComponentType | undefined,
    resourceName: string,
  ) => {
    const resourcePermission = getResourcePermission(resourceName)
    return component
      ? withPermissionRoutePage(component, resourcePermission)
      : undefined
  }

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LoadingProvider>
          <Admin
            disableTelemetry
            theme={theme}
            layout={Layout}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
          >
            <CustomRoutes>
              <Route path="/" element={<HomePage />} />
            </CustomRoutes>

            {/* Account */}
            <Resource
              name="admin"
              list={permissionComponent(AdminList, 'admin')}
              create={permissionComponent(AdminCreate, 'admin')}
              edit={permissionComponent(AdminEdit, 'admin')}
            />
            <Resource
              name="partnerUser"
              options={{ label: 'Clinic (Partner)' }}
              list={permissionComponent(PartnerUserList, 'partnerUser')}
              create={permissionComponent(PartnerUserCreate, 'partnerUser')}
              edit={permissionComponent(PartnerUserEdit, 'partnerUser')}
            />

            {/* Doctor */}
            <Resource
              name="doctor"
              list={permissionComponent(DoctorList, 'doctor')}
              create={permissionComponent(DoctorCreate, 'doctor')}
              edit={permissionComponent(DoctorEdit, 'doctor')}
            />
            <Resource
              name="doctorCategory"
              list={permissionComponent(DoctorCategoryList, 'doctorCategory')}
              create={permissionComponent(
                DoctorCategoryCreate,
                'doctorCategory',
              )}
              edit={permissionComponent(DoctorCategoryEdit, 'doctorCategory')}
            />
            <Resource
              name="doctorKycData"
              list={permissionComponent(DoctorKycDataList, 'doctorKycData')}
              edit={permissionComponent(DoctorKycDataEdit, 'doctorKycData')}
            />
            <Resource
              name="doctorScheduler"
              options={{ label: 'Doctor Scheduler' }}
              list={permissionComponent(DoctorScheduler, 'doctorScheduler')}
            />
            <Resource
              name="appointment"
              list={permissionComponent(AppointmentList, 'appointment')}
              create={permissionComponent(AppointmentCreate, 'appointment')}
              edit={permissionComponent(AppointmentEdit, 'appointment')}
            />
            <Resource
              name="doctorQueueMonitoring"
              options={{ label: 'Queue Monitoring' }}
              list={permissionComponent(
                QueueMonitoring,
                'doctorQueueMonitoring',
              )}
            />
            <Resource
              name="doctorConsent"
              list={permissionComponent(DoctorConsentList, 'doctorConsent')}
              edit={permissionComponent(DoctorConsentEdit, 'doctorConsent')}
            />

            {/* Patient */}
            <Resource
              name="patient"
              list={permissionComponent(PatientList, 'patient')}
              edit={PatientEdit}
            />
            <Resource
              name="patientKycData"
              list={permissionComponent(PatientKycDdataList, 'patientKycData')}
              edit={permissionComponent(PatientKycDataEdit, 'patientKycData')}
            />
            <Resource
              name="patientConsent"
              list={permissionComponent(PatientConsentList, 'patientConsent')}
              edit={permissionComponent(PatientConsentEdit, 'patientConsent')}
            />
            <Resource
              name="notification"
              list={permissionComponent(NotificationList, 'notification')}
              create={permissionComponent(NotificationCreate, 'notification')}
            />

            {/* Product */}
            {/* <Resource
              name="product"
              list={permissionComponent(ProductList, 'product')}
              create={permissionComponent(ProductCreate, 'product')}
              edit={permissionComponent(ProductEdit, 'product')}
            /> */}
            <Resource
              name="productCategory"
              list={permissionComponent(ProductCategoryList, 'productCategory')}
              create={permissionComponent(
                ProductCategoryCreate,
                'productCategory',
              )}
              edit={permissionComponent(ProductCategoryEdit, 'productCategory')}
            />
            <Resource
              name="productBrand"
              list={permissionComponent(ProductBrandList, 'productBrand')}
              create={permissionComponent(ProductBrandCreate, 'productBrand')}
              edit={permissionComponent(ProductBrandEdit, 'productBrand')}
            />
            {/* <Resource
              name="productVariant"
              list={permissionComponent(ProductVariantList, 'productVariant')}
              create={permissionComponent(
                ProductVariantCreate,
                'productVariant',
              )}
              edit={permissionComponent(ProductVariantEdit, 'productVariant')}
            /> */}
            <Resource
              name="productSearch"
              list={permissionComponent(
                ProductSearchAppPlaygroundList,
                'productSearch',
              )}
            />

            {/* Deal & Clinic */}
            <Resource
              name="procedure"
              list={permissionComponent(ProcedureList, 'procedure')}
              create={permissionComponent(ProcedureCreate, 'procedure')}
              edit={permissionComponent(ProcedureEdit, 'procedure')}
            />
            <Resource
              name="procedureCategory"
              list={permissionComponent(
                ProcedureCategoryList,
                'procedureCategory',
              )}
              create={permissionComponent(
                ProcedureCategoryCreate,
                'procedureCategory',
              )}
              edit={permissionComponent(
                ProcedureCategoryEdit,
                'procedureCategory',
              )}
            />
            <Resource
              name="procedureSearch"
              list={permissionComponent(
                ProcedureSearchAppPlaygroundList,
                'procedureSearch',
              )}
            />
            <Resource
              name="partner"
              options={{ label: 'Clinic (Partner)' }}
              list={permissionComponent(PartnerList, 'partner')}
              create={permissionComponent(PartnerCreate, 'partner')}
              edit={permissionComponent(PartnerEdit, 'partner')}
            />
            <Resource
              name="partnerBranch"
              options={{ label: 'Branch' }}
              create={permissionComponent(PartnerBranchCreate, 'partnerBranch')}
              edit={permissionComponent(PartnerBranchEdit, 'partnerBranch')}
            />

            {/* Carousel */}
            <Resource
              name="carouselProcedure"
              list={permissionComponent(
                CarouselProcedureList,
                'carouselProcedure',
              )}
              create={permissionComponent(
                CarouselProcedureCreate,
                'carouselProcedure',
              )}
              edit={permissionComponent(
                CarouselProcedureEdit,
                'carouselProcedure',
              )}
            />
            <Resource
              name="carouselItemProcedure"
              create={permissionComponent(
                CarouselItemProcedureCreate,
                'carouselitemProcedure',
              )}
              edit={permissionComponent(
                CarouselItemProcedureEdit,
                'carouselitemProcedure',
              )}
            />

            {/* Flash sale */}
            <Resource
              name="flashsaleProduct"
              list={permissionComponent(
                FlashsaleProductList,
                'flashsaleProduct',
              )}
              create={permissionComponent(
                FlashsaleProductCreate,
                'flashsaleProduct',
              )}
              edit={permissionComponent(
                FlashsaleProductEdit,
                'flashsaleProduct',
              )}
            />
            <Resource
              name="flashsaleItemProduct"
              create={permissionComponent(
                FlashsaleItemProductCreate,
                'flashsaleItemProduct',
              )}
              edit={permissionComponent(
                FlashsaleItemProductEdit,
                'flashsaleItemProduct',
              )}
            />
            <Resource
              name="flashsaleProcedure"
              list={permissionComponent(
                FlashsaleProcedureList,
                'flashsaleProcedure',
              )}
              create={permissionComponent(
                FlashsaleProcedureCreate,
                'flashsaleProcedure',
              )}
              edit={permissionComponent(
                FlashsaleProcedureEdit,
                'flashsaleProcedure',
              )}
            />
            <Resource
              name="flashsaleItemProcedure"
              create={permissionComponent(
                FlashsaleItemProcedureCreate,
                'flashsaleItemProcedure',
              )}
              edit={permissionComponent(
                FlashsaleItemProcedureEdit,
                'flashsaleItemProcedure',
              )}
            />

            {/* Coupon */}
            <Resource
              name="couponGroup"
              list={permissionComponent(CouponGroupList, 'couponGroup')}
              show={permissionComponent(CouponGroupShow, 'couponGroup')}
              create={permissionComponent(CouponGroupCreate, 'couponGroup')}
            />
            <Resource
              name="coupon"
              list={permissionComponent(CouponList, 'coupon')}
            />

            {/* Banner */}
            <Resource
              name="patientHomeBanner"
              options={{ label: 'Patient app home banner' }}
              list={permissionComponent(
                PatientHomeBannerList,
                'patientHomeBanner',
              )}
              create={permissionComponent(
                PatientHomeBannerCreate,
                'patientHomeBanner',
              )}
              edit={permissionComponent(
                PatientHomeBannerEdit,
                'patientHomeBanner',
              )}
            />
            <Resource
              name="productBanner"
              options={{ label: 'Product Banner' }}
              list={permissionComponent(ProductBannerList, 'productBanner')}
              create={permissionComponent(ProductBannerCreate, 'productBanner')}
              edit={permissionComponent(ProductBannerEdit, 'productBanner')}
            />
            <Resource
              name="procedureBanner"
              options={{ label: 'Procedure Banner' }}
              list={permissionComponent(ProcedureBannerList, 'procedureBanner')}
              create={permissionComponent(
                ProcedureBannerCreate,
                'procedureBanner',
              )}
              edit={permissionComponent(ProcedureBannerEdit, 'procedureBanner')}
            />
            <Resource
              name="doctorBanner"
              options={{ label: 'Doctor Banner' }}
              list={permissionComponent(DoctorBannerList, 'doctorBanner')}
              create={permissionComponent(DoctorBannerCreate, 'doctorBanner')}
              edit={permissionComponent(DoctorBannerEdit, 'doctorBanner')}
            />
            <Resource
              name="waitingBanner"
              options={{ label: 'Waiting Banner' }}
              list={permissionComponent(WaitingBannerList, 'waitingBanner')}
              create={permissionComponent(WaitingBannerCreate, 'waitingBanner')}
              edit={permissionComponent(WaitingBannerEdit, 'waitingBanner')}
            />

            {/* Patient app article */}
            <Resource
              name="article"
              list={permissionComponent(ArticleList, 'article')}
              create={permissionComponent(ArticleCreate, 'article')}
              edit={permissionComponent(ArticleEdit, 'article')}
            />

            {/* Shipping provider */}
            <Resource
              name="shippingProvider"
              list={permissionComponent(
                ShippingProviderList,
                'shippingProvider',
              )}
              edit={permissionComponent(
                ShippingProviderEdit,
                'shippingProvider',
              )}
            />
          </Admin>
        </LoadingProvider>
      </LocalizationProvider>
    </BrowserRouter>
  )
}

export default App

import { z } from 'zod'

import {
  ProductModel,
  ProductPictureModel,
  ProductVideoModel,
  VariantModel,
  WarehouseModel,
} from '../generated/zod'

export const ProductSchema = ProductModel.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  searchField: true,
}).extend({
  active: z.boolean().default(false),
  searchable: z.boolean().default(true),
  priority: z.number().default(0),
  categories: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  pictures: z.array(
    ProductPictureModel.omit({
      id: true,
      productId: true,
      createdAt: true,
      updatedAt: true,
    }),
  ),
  video: z.array(
    ProductVideoModel.omit({
      id: true,
      productId: true,
      createdAt: true,
      updatedAt: true,
    }),
  ),
})

export const translationSchema = z.object({
  th: z.string().default(''),
  en: z.string().default(''),
})
export const PictureSchema = z.object({
  rawFile: z.object({}).optional().nullable(),
  src: z.string().min(1),
  gsPath: z.string().min(1),
  name: z.string().min(1),
})
export const ValidateProductImportSKUSchema = z.object({
  sku: z.string().min(1),
  picture: z.string(),
  description: translationSchema,
  label: translationSchema,
  size: VariantModel.shape.size.default(1),
  unit: VariantModel.shape.unit,
  sellingUnit: VariantModel.shape.sellingUnit,
  cost: VariantModel.shape.cost,
  tagPrice: VariantModel.shape.tagPrice,
  sellingPrice: VariantModel.shape.sellingPrice,
  canExpress: z.boolean().optional().nullable(),
  warehouse: z.string(),
})
export const ValidateProductImportSchema = z.object({
  data: z
    .array(
      z.object({
        brand: z.string().min(1),
        subCategory: z.string().min(1),
        productPictures: z.string().optional().nullable(),
        name: translationSchema,
        regNo: z.string().optional(),
        productType: ProductModel.shape.type,
        salesType: ProductModel.shape.salesType,
        auxiliaryLabel: ProductModel.shape.auxiliaryLabel,
        drugType: ProductModel.shape.drugType,
        productForm: z.object({
          form1: z.string().optional().nullable(),
          form2: z.string().optional().nullable(),
          form3: z.string().optional().nullable(),
        }),
        skinType: z.object({
          type1: z.string().optional().nullable(),
          type2: z.string().optional().nullable(),
          type3: z.string().optional().nullable(),
        }),
        concern: z.object({
          concern1: z.string().min(1),
          concern2: z.string().nullish(),
          concern3: z.string().nullish(),
        }),
        attribute: z.object({
          attribute1: z.string().min(1),
          attribute2: z.string().nullish(),
          attribute3: z.string().nullish(),
        }),
        property: translationSchema,
        description: translationSchema,
        sideEffect: translationSchema,
        caution: translationSchema,
        ingredient: z.string().optional().nullable(),
        usage: ProductModel.shape.usage,
        use: z.object({
          amount: ProductModel.shape.useAmount,
          unit: ProductModel.shape.useUnit,
          frequency: ProductModel.shape.useFrequency,
          time: ProductModel.shape.useTime,
        }),
        instruction: translationSchema,
        pharmacyNote: z.string().optional().nullable(),
        sku: z.array(ValidateProductImportSKUSchema),
      }),
    )
    .min(1)
    .max(100),
  pictures: z
    .array(
      z.object({
        rawFile: z.object({}).optional().nullable(),
        src: z.string().min(1),
        gsPath: z.string().min(1),
        name: z.string().min(1),
      }),
    )
    .max(200),
})
export const BulkProductImportSchema = z.object({
  nameTr: translationSchema,
  searchField: ProductModel.shape.searchField,
  regNo: ProductModel.shape.regNo,
  descriptionTr: translationSchema,
  instructionTr: translationSchema,
  sideEffectTr: translationSchema,
  cautionTr: translationSchema,
  salesType: ProductModel.shape.salesType,
  productForms: ProductModel.shape.productForms,
  type: ProductModel.shape.type,
  auxiliaryLabel: ProductModel.shape.auxiliaryLabel,
  drugType: ProductModel.shape.drugType,
  priority: ProductModel.shape.priority,
  skinType: ProductModel.shape.skinType,
  propertyTr: translationSchema,
  concerns: ProductModel.shape.concerns,
  attributes: ProductModel.shape.attributes,
  useFrequency: ProductModel.shape.useFrequency,
  useTime: ProductModel.shape.useTime,
  brandId: ProductModel.shape.brandId,
  pharmacyNote: ProductModel.shape.pharmacyNote,
  usage: ProductModel.shape.usage,
  useUnit: ProductModel.shape.useUnit,
  useAmount: ProductModel.shape.useAmount,
  ingredient: ProductModel.shape.ingredient,
  categoryIds: z.array(z.number()),
  productPictures: z.array(PictureSchema),
  variants: z.array(
    ValidateProductImportSKUSchema.omit({
      picture: true,
      warehouse: true,
    }).extend({
      pictures: z.array(PictureSchema),
      werehouses: z.array(WarehouseModel.shape.code),
    }),
  ),
})
export type ValidateProductImportType = z.infer<
  typeof ValidateProductImportSchema
>
export type ProductType = z.infer<typeof ProductSchema>
export type ValidateProductImportSKUSchemaType = z.infer<
  typeof ValidateProductImportSKUSchema
>
export type BulkProductImportSchemaType = z.infer<
  typeof BulkProductImportSchema
>

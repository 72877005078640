import { TrFieldModel } from '@ssch-backend/share-model'
import {
  generateSearchField,
  preprocessBoolean,
} from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'

import { CustomFieldTypeSchema } from '../generated/trpc/schemas'
import { CustomFieldModel, CustomFieldModelResponse } from '../generated/zod'

const SEARCH_FIELD_DELIMITER = '||'

export type CustomFieldType =
  typeof CustomFieldTypeSchema.Enum[keyof typeof CustomFieldTypeSchema.Enum]
export type CustomField = z.infer<typeof CustomFieldModel>

export const priorityAndFieldOptionTrModel = z.object({
  priority: z.number().int().min(0).default(0),
  fieldOptionsTr: TrFieldModel,
})

export const SearchCustomFieldInputModel = z.object({
  id: z.number().optional(),
  ids: z.preprocess(
    (val) =>
      typeof val === 'string' ? String(val).split(',').map(Number) : val,
    z.number().array().optional(),
  ),
  page: z.number().min(1).default(1),
  pageSize: z.number().min(1).max(500).default(10),
  orderBy: z
    .enum(['fieldType', 'priority', 'createdAt', 'updatedAt'])
    .default('createdAt'),
  orderDirection: z.enum(['asc', 'desc']).default('desc'),
  searchField: z.string().optional(),
  type: CustomFieldTypeSchema.optional(),
  types: z.preprocess(
    (val) => (typeof val === 'string' ? String(val).split(',') : val),
    z.array(CustomFieldTypeSchema).optional(),
  ),
  isActive: z.preprocess(preprocessBoolean, z.boolean().optional()),
})
export type SearchCustomFieldInput = z.infer<typeof SearchCustomFieldInputModel>

export const refineCreateSearchField = <T extends Partial<CustomField>>(
  data: T,
): T & Pick<CustomField, 'searchField'> => {
  const searchField = data.fieldOptionsTr
    ? generateSearchField(data.fieldOptionsTr, SEARCH_FIELD_DELIMITER)
    : ''
  return { ...data, searchField }
}

export const refineUpdateSearchField = <T extends Partial<CustomField>>(
  data: T,
): T & Partial<Pick<CustomField, 'searchField'>> => {
  const searchField = data.fieldOptionsTr
    ? generateSearchField(data.fieldOptionsTr, SEARCH_FIELD_DELIMITER)
    : undefined
  return { ...data, searchField }
}

export const CreateCustomFieldModel = CustomFieldModel.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  active: true,
  searchField: true,
})
  .merge(priorityAndFieldOptionTrModel)
  .transform(refineCreateSearchField)
export type CreateCustomField = z.infer<typeof CreateCustomFieldModel>

export const CreateCustomFieldRequestModel = CreateCustomFieldModel
export type CreateCustomFieldRequest = z.infer<
  typeof CreateCustomFieldRequestModel
>

export const UpdateCustomFieldBaseModel = CustomFieldModel.omit({
  createdAt: true,
  updatedAt: true,
})
  .merge(priorityAndFieldOptionTrModel)
  .partial()

export const UpdateCustomFieldModel = UpdateCustomFieldBaseModel.required({
  id: true,
}).transform(refineUpdateSearchField)
export type UpdateCustomField = z.infer<typeof UpdateCustomFieldModel>

export const UpdateCustomFieldRequestModel = UpdateCustomFieldBaseModel.omit({
  id: true,
})
  .extend({
    customFieldId: z.number(),
  })
  .required({ customFieldId: true })
  .transform(refineUpdateSearchField)
export type UpdateCustomFieldRequest = z.infer<
  typeof UpdateCustomFieldRequestModel
>

export const SearchCustomFieldResponseModel = z.object({
  data: z.array(
    CustomFieldModelResponse.merge(
      z.object({
        fieldOptionsTr: TrFieldModel,
      }),
    ),
  ),
  pagination: z.object({
    total: z.number(),
    page: z.number(),
    pageSize: z.number(),
    totalPages: z.number(),
  }),
})

export type SearchCustomFieldResponse = z.infer<
  typeof SearchCustomFieldResponseModel
>

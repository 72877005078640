import { z } from 'zod'

export const TrFieldModel = z.object({
  en: z.string().trim().min(1),
  th: z.string().trim().min(1),
})
export type TrField = z.infer<typeof TrFieldModel>

export const TrFieldOptionalModel = z
  .object({
    en: z.string().trim(),
    th: z.string().trim(),
  })
  .optional()
export type TrFieldOptional = z.infer<typeof TrFieldOptionalModel>
